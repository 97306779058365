import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import router from '@/router'
import { store } from '@/store'

function App() {
  return (
    <div style={style}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </div>
  )
}

const style = {
  width: '100vw',
  height: '100vh',
  background: 'linear-gradient(rgb(14, 24, 52) 3%,rgb(9, 19, 38) 8%'
}

export default App
