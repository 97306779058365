import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AreaData } from '@/types/type'

const initialState = {
} as AreaData

export const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setAreaData: (state, action: PayloadAction<AreaData>) => {
      state.name = action.payload.name
      state.count = action.payload.count
      state.datas = action.payload.datas
    }
  },
})

export const { setAreaData } = areaSlice.actions
export default areaSlice.reducer