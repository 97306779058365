import { configureStore } from '@reduxjs/toolkit'

import areaSlice from './slices/area.slice'

export const store = configureStore({
    reducer: {
        area: areaSlice
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
