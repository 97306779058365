import { Suspense, lazy } from 'react'
import { createHashRouter, Navigate } from 'react-router-dom'

export const routes = [
  {
    path: '/',
    element: lazyLoad('Hanzhong'),
    children: []
  },
  {
    path: '/PageNotFound',
    element: lazyLoad('PageNotFound')
  },
  {
    path: '*',
    element: <Navigate to="/PageNotFound" />
  }
]

function lazyLoad(moduleName: string) {
  const Module = lazy(() => import(`@/pages/${moduleName}`))
  return (
    <Suspense fallback={<div>加载中...</div>}>
      <Module />
    </Suspense>
  )
}

export default createHashRouter(routes)
